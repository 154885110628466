body {
  display: block ;
}
.cabecera-exitoso {
  margin-left: 20%; 
  margin-right: 20%; 
  margin-top: 5%;}

  .backgroundSaleCatastrofico {
    padding-top: 20px;
    text-align: center;
  }

  .margingTop-btn {
    margin-top: 50px;
  }

  .btn.btn-secundary {
    border: 2px solid;
    color: #ec8504;
  }

  
  .row.fondoGrisSC {
    background-color: whitesmoke;
    color: #707070;
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 10px;
    border-radius: 10px;
    padding: 20px;
  }

  .borderPunteadoSC {
    border: 3px solid;
    border-color: #aaaaaa;
    border-style: dotted;
    border-top: 0;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
  }

  
  .TitleConfirmation {
    font-size: 40px;
    font-family: 'Poppins';
  }

  
  .CongratSC {
    font-size: 30px;
    font-weight: bold;
    font-family: 'Poppins';
  }

  
  .backgroundSale {
    background-size: cover;
    color: white;
    padding-top: 60px;
    padding-bottom: 30px;
    padding-left: 40px;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    background: -moz-linear-gradient(top, #e03c2a 0%, #ed8b00 100%);
    background: -webkit-gradient(left top, left bottom, color-stop(0%, #e03c2a), color-stop(100%, #ed8b00));
    background: -webkit-linear-gradient(top, #e03c2a 0%, #ed8b00 100%);
    background: -o-linear-gradient(top, #e03c2a 0%, #ed8b00 100%);
    background: -ms-linear-gradient(top, #e03c2a 0%, #ed8b00 100%);
    background: linear-gradient(to bottom, #e03c2a 0%, #ed8b00 100%); 
  }

  div {
    display: block;
}