@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700&display=swap');
html,body {
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #4c4b63;
}

h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6{
  font-weight: 600;
}

img{
  height: 100%;
}

p{
  margin: 0px;
}

a{
  text-decoration: none;
  color: #4c4b63;
}
a:hover{
  text-decoration: none;
  color: #ef6c00;
}
/* =============== Cabecera =============== */
.cabecera{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  box-shadow: 5px 5px 5px #e7e7e7;
  font-family: 'Montserrat', sans-serif;
}

.cabecera_logo{
  margin: 15px;
  height: 40px;
}

/* =============== Pie Pagina =============== */
.piePagina{
  background-color: #ef6c00;
  display: flex;
  flex-direction: row;
  padding: 20px;
}

.piePagina_logo{
  margin: 10px;
  height: 40px;
}

.piePagina_contacto{
  list-style: none;
  padding: 0;
  margin: 0;
  color: white;
  position: relative;
  margin-left: 40px;
}

.piePagina_contacto::before{
  content: "";
  margin: 11px 20px;
  background: white;
  box-shadow: none;
  box-sizing: unset;
  height: 50px;
  width: 2px;
  position: absolute;
  left: -41px;
  top: calc(50% - 35px);
}

.piePagina_contacto--uppercase{
  text-transform: uppercase;
}

.piePagina_contacto--negrita{
  font-weight: 700;
}

/* =============== Contenido =============== */
.informacion{
  padding: 50px 40px;
}

.informacion_icono{
  margin: 0;
  padding: 20px 0px;
}

.informacion_icono svg{
  fill: #ef6c00;
}

.paso_contenedor{
  list-style: none;
  padding: 0;
  margin: 25px 0px 0px;
  display: flex;
  flex-direction: row;
}

.paso{
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  width: 33%;
}

.paso_icono{
  position: relative;
  z-index: 1;
}

.paso_icono svg{
  fill: #ef6c00;
  width: 30px;
  height: 30px;
}

.paso_numero{
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 2px solid #9d9caf;
  color: #9d9caf;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  position: relative;
  z-index: 1;
  background-color: white;
}

.paso_nombre{
  font-size: 0.9rem;
  font-weight: 600;
  color: #9d9caf;
}

.paso_nombre--finalizado{
  color: #ef6c00;
}

.paso::after{
  content: "";
  width: 50%;
  height: 3px;
  background-color: #9d9caf;
  position: absolute;
  top: 25%;
  right: 0px;
  z-index: 0;
}

.paso::before{
  content: "";
  width: 50%;
  height: 3px;
  background-color: #9d9caf;
  position: absolute;
  top: 25%;
  left: 0px;
  z-index: 0;
}

.paso--finalizado::before{
  background-color: #ef6c00;
}

.datos{
  padding: 20px 40px 50px;
  box-shadow: 0px 4px 5px #e7e7e7;
  margin-bottom: 50px;
  border-radius: 0.5rem;
}

.datos_cabecera{
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.datos_cabecera a{
  text-decoration: underline;
  vertical-align: bottom;
}

.datos_cabecera a:hover{
  text-decoration: underline;
  color: #4c4b63;
}

.datos_cabeceraTitulo{
  margin: 0;
  padding: 10px 0px;
}

.datos_cabeceraTitulo svg{
  margin-right: 10px;
  width: 25px;
  height: 25px;
}

.datos_cabeceraTitulo span{
  vertical-align: middle;
}

.datos_cabecera{
  border-bottom: 1px solid #9d9caf;
  margin-bottom: 15px;
}

.form-control{
  border: 2px solid #d9d9d9;
  padding: 10px 15px;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: textfield;
}

.form-control:hover,
.form-control:focus,
.form-control:active{
  outline: none;
  box-shadow: none;
  border-color: #d9d9d9;
}

.form-label {
  margin: 0px;
  font-size: 0.85rem;
  font-weight: 800;
  position: relative;
  background: white;
  left: 10px;
  top: 15px;
  padding: 5px 10px;
}

.form-contenedor{
  position: relative;
}

.form-icono{
  position: absolute;
  right: 9px;
  top: 40px;
  cursor: pointer;
}

.form-icono svg{
  fill: #e82d2d;
  width: 20px;
}

.form-error.form-control,
.form-error.form-control:hover,
.form-error.form-control:focus,
.form-error.form-control:active{
  border-color: #e82d2d;
}

.contador{
  display: flex;
  align-items: flex-end;
}

.contador-image{
  width: 50px;
  display: inline-block;
  height: 50px;
  background: #f5f4f4;
  border-radius: 50%;
  fill: #6c6b80;
  margin-right: 15px;
}

.contador-image svg{
  width: 50px;
  height: 50px;
}

.contador-controles{
  display: flex;
  flex-direction: column;
  margin-left: 10px;
}

.contador-controles .btn-light,
.contador-controles .btn-light:hover,
.contador-controles .btn-light:focus,
.contador-controles .btn-light:active{
  background: #ef6c00;
  color: white;
  padding: 0px 13px;
  height: 22px;
  font-size: 1.2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  outline: none;
  box-shadow: none;
  border: 0px;
}

.precio{
  border: 2px solid #ef6c00;
  padding: 10px 15px;
  border-radius: 0.5rem;
}

.precio_titulo{
  font-size: 0.7rem;
  font-weight: 700;
}

.precio_pesos{
  color: #ef6c00;
  font-weight: 800;
  font-size: 2rem;
  margin: 0px;
}

.precio_uf{
  font-size: 1.3rem;
  font-weight: 400;
}

.precio_nota{
  font-size: 0.6rem;
  padding: 10px 0px 0px 30px;
  border-top: 1px solid;
  position: relative;
  margin-top: 7px;
}

.precio_nota svg{
  position: absolute;
  left: 4px;
  top: 12px;
  width: 20px;
  height: 20px;
  fill: #4c4b63;
}

.seccionNomina_elemento{
  position: relative;
  padding-left: 20px;
}
.seccionNomina_elemento::before{
  content: "";
  width: 25px;
  height: 25px;
  border: 1px solid #ef6c00;
  border-radius: 50%;
  display: inline-block;
  position: absolute;
  left: -18px;
  top: -2px;
}

.seccionNomina ::marker {
  content: counter(list-item);
  font-size: 1rem;
  color: #ef6c00;
}

.seccionNomina_titulo{
  font-size: 0.9rem;
  font-weight: 600;
  margin-bottom: 15px;
}

.seccionNomina_parrafo{
  font-size: 0.8rem;
  margin: 20px 0px;
}

.seccionNomina_cajaBtn{
  display: flex;
  justify-content: center;
  align-items: center;
}

.btnDescargarFormato,
.btnDescargarFormato:hover,
.btnDescargarFormato:focus,
.btnDescargarFormato:active,
.btnDescargarFormato.btn:first-child:active{
  border: none;
  padding: 15px 20px;
  border-radius: 2rem;
  font-size: 0.8rem;
  font-weight: 700;
  box-shadow: 0px 4px 5px #e7e7e7;
}

.btnDescargarFormato{
  background-color: white;
  color: black;
}

.btnDescargarFormato:hover,
.btnDescargarFormato:focus,
.btnDescargarFormato:active,
.btnDescargarFormato.btn:first-child:active{
  background-color: #ef6c00;
  color: white;
}

.btnDescargarFormato:hover svg,
.btnDescargarFormato:focus svg,
.btnDescargarFormato:active svg,
.btnDescargarFormato.btn:first-child:active svg{
  fill: white;
}

.btnDescargarFormato svg{
  margin-right: 10px;
  width: 25px;
  height: 25px;
}

.validacion_titulo{
  font-size: 0.8rem;
  font-weight: 600;
  margin-bottom: 15px;
}

.validacion_contenedor{
  display: flex;
}

.validacion_texto{
  color: #4c4b63;
  font-size: 0.65rem;
  font-weight: 500;
  width: 270px;
  padding: 0px 10px;
}

.validacion_texto--error{
  color: #fe2302;
}

.validacion_icono svg{
  fill: #a4c93f;
  width: 20px;
  height: 20px;
}

.validacion_icono--error svg{
  fill: #e91515;
}

.btnVerificar,
.btnVerificar:hover,
.btnVerificar:focus,
.btnVerificar:active,
.btnVerificar.btn:first-child:active{
  padding: 5px 50px;
  background: #e3e2e5;
  color: #707070;
  font-size: 0.8rem;
  font-weight: 600;
  border: 0;
}

.btn--gris.btnVerificar,
.btn--gris.btnVerificar:hover,
.btn--gris.btnVerificar:focus,
.btn--gris.btnVerificar:active{
  background-color: #e2e2e5;
  color: #707070;
  cursor: not-allowed;
}

.btn--naranja.btnVerificar,
.btn--naranja.btnVerificar:hover,
.btn--naranja.btnVerificar:focus,
.btn--naranja.btnVerificar:active{
  background-color: #f3e0c4;
  color: #ef6c00;
  cursor: pointer;
  height: 35px;
}

.cargar{
  position: relative;
  display: flex;
  align-items: center;
}

.cargar_icono{
  display: flex;
  align-items: baseline;
  position: relative;
  width: 50px;
  height: 50px;
}

.cargar_icono svg:first-child{
  position: relative;
  z-index: 1;
  width: 100%;
  height: 100%;
}

.cargar_icono svg:last-child{
  position: relative;
  z-index: 1;
  left: -7px;
  width: 50%;
  height: 50%;
}

.cargar_icono--gris svg:first-child{
  fill: #4c4b63;
}

.cargar_icono--gris svg:last-child{
  fill: #c3c1c1;
}

.cargar_icono--naranja svg:last-child{
  fill: #ef6c00;
}

.cargar_btn{
  position: relative;
  cursor: pointer;
  padding: 7px 30px;
  font-size: 0.8rem;
  font-weight: 600;
  border-radius: 0.4rem;
  border: none;
}

.btn--gris{
  background-color: #e2e2e5;
  color: #707070;
}

.btn--naranja{
  background-color: #f3e0c4;
  color: #ef6c00;
}

.cargar_input{
  position: absolute;
  z-index: 999999;
  width: 100%;
  height: 100%;
  top: 0;
  opacity: 0;
  left: 0;
  cursor: pointer;
}

.adjuntar{
  display: flex;
  justify-content: space-between;
  border: 1px dashed #c3c1c1;
  padding: 10px 15px;
}

.adjunto{
  width: 270px
}

.adjunto_titulo{
  font-size: 0.8rem;
  font-weight: 600;
}

.adjunto_detalle{
  display: flex;
}

.adjunto_descripcion{
  font-size: 0.8rem;
  min-width: 80%;
  margin-right: 5px;
}

.adjunto_descripcion span{
  display: block;
}

.adjunto_icono{
  padding-right: 8px;
  padding-top: 3px
}

.adjunto_icono svg{
  width: 20px;
  height: 28px;
}

.adjunto_eliminar{
  border: none;
  background: none;
  padding: 0;
}

.adjunto_eliminar svg{
  width: 18px;
  height: 30px;
  fill: #4c4b63;
}

.adjunto_descripcion span:first-child{
  font-weight: 600;
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.adjunto_descripcion span:last-child{
  font-size: 0.7rem;
}

.adjunto_detalle--grisClaro .adjunto_icono svg{
  fill: #c3c1c1;
}

.adjunto_detalle--grisClaro .adjunto_descripcion{
  color: #c3c1c1;
}

.adjunto_detalle--gris .adjunto_icono svg{
  fill: #4c4b63;
}

.adjunto_detalle--gris .adjunto_descripcion{
  fill: #4c4b63;
}

.adjunto_detalle--error .adjunto_icono svg{
  fill: #e91515;
}

.adjunto_accion{
  display: none;
}

.adjunto_accion--cargado{
  display: inline-flex;
}

.adjunto_accion--cargado svg{
  fill: #a4c93f;
}

.errores_titulo{
  font-size: 0.9rem;
  font-weight: 600;
  padding: 20px 0;
  margin: 0;
}

.errores_archivo{
  margin-bottom: 15px;
  font-size: 0.8rem;
}

.no-display{
  display: none;
}

.pago{
  text-align: center;
  padding: 0 20px 50px;
}

.pago_btn.btn-light,
.pago_btn.btn-light:hover,
.pago_btn.btn-light:focus,
.pago_btn.btn-light:active{
  background: #ef6c00;
  color: white;
  padding: 10px 40px;
  font-weight: 500;
  outline: none;
  box-shadow: none;
  border: 0;
}

.rnc__notification-item{
  margin-bottom: 0px;
}

.rnc__notification-container--bottom-full .rnc__notification-item, 
.rnc__notification-container--top-full .rnc__notification-item {
  width: 100%;
}

.rnc__notification-message{
  padding-left: 50px;
  position: relative;
}

.rnc__notification-message svg{
  width: 20px;
  height: 25px;
  fill: #FFF;
  margin-right: 20px;
}

.rnc__notification-icono{
  position: absolute;
  left: 15px;
  display: flex;
  align-items: center;
  height: 100%;
}

.declaracion {
  font-size: 0.9rem;
  margin: 40px 30px 0;
}

.form-check-input:checked{
  background-color: #ef6c00;
  border-color: #ef6c00;
}

.form-check-input:focus {
	border-color: #ef6c00ba;
	outline: 0;
	box-shadow: 0 0 0 .25rem rgba(239,108,0,.25);
}

.form-switch .form-check-input:focus {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='%23f3e0c4'/%3E%3C/svg%3E");
}

/* Spinner Vida Camara*/
.transparente {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  z-index: 1051;
  text-align: center;
  color: white;
}

.naranjo {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #ed8d00;
  z-index: 3;
  text-align: center;
  color: white;
}

.lds-roller {
  display: inline-block;
  position: relative;
  width: 64px;
  height: 64px;
}

.lds-roller div {
  animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  transform-origin: 32px 32px;
}

.lds-roller div:after {
  content: " ";
  display: block;
  position: absolute;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: #fff;
  margin: -3px 0 0 -3px;
}

.lds-roller div:nth-child(1) {
  animation-delay: -0.036s;
}

.lds-roller div:nth-child(1):after {
  top: 50px;
  left: 50px;
}

.lds-roller div:nth-child(2) {
  animation-delay: -0.072s;
}

.lds-roller div:nth-child(2):after {
  top: 54px;
  left: 45px;
}

.lds-roller div:nth-child(3) {
  animation-delay: -0.108s;
}

.lds-roller div:nth-child(3):after {
  top: 57px;
  left: 39px;
}

.lds-roller div:nth-child(4) {
  animation-delay: -0.144s;
}

.lds-roller div:nth-child(4):after {
  top: 58px;
  left: 32px;
}

.lds-roller div:nth-child(5) {
  animation-delay: -0.18s;
}

.lds-roller div:nth-child(5):after {
  top: 57px;
  left: 25px;
}

.lds-roller div:nth-child(6) {
  animation-delay: -0.216s;
}

.lds-roller div:nth-child(6):after {
  top: 54px;
  left: 19px;
}

.lds-roller div:nth-child(7) {
  animation-delay: -0.252s;
}

.lds-roller div:nth-child(7):after {
  top: 50px;
  left: 14px;
}

.lds-roller div:nth-child(8) {
  animation-delay: -0.288s;
}

.lds-roller div:nth-child(8):after {
  top: 45px;
  left: 10px;
}

@keyframes lds-roller {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@media (min-width: 992px) and (max-width: 1199.98px) {
  
  .form-label {
    font-size: 0.75rem;
    left: 6px;
    padding: 5px;
  }

  .contador-image {
    width: 40px;
    height: 40px;
    margin-right: 5px;
  }

  .contador-image svg {
    width: 40px;
    height: 40px;
  }

  .contador-controles .btn-light, 
  .contador-controles .btn-light:hover, 
  .contador-controles .btn-light:focus, 
  .contador-controles .btn-light:active {
    padding: 0px 10px;
  }

  .precio_titulo {
    font-size: 0.6rem;
  }

  .precio_pesos {
    font-size: 1.5rem;
  }
  
  .cargar_btn{
    padding: 7px 15px;
  }

  .cargar_icono {
    width: 40px;
    height: 40px;
  }

  .adjunto {
    width: 170px;
  }

  .adjunto_icono {
    padding-right: 5px;
    padding-top: 0px;
  }

  .adjunto_icono svg {
    width: 15px;
    height: 20px;
  }

  .adjunto_descripcion {
    min-width: 70%;
  }

  .btn--gris.btnVerificar, 
  .btn--gris.btnVerificar:hover, 
  .btn--gris.btnVerificar:focus, 
  .btn--gris.btnVerificar:active {
    height: 35px;
  }

  .validacion_titulo {
    font-size: 0.7rem;
    margin-bottom: 10px;
  }
}

@media (max-width: 991.98px) {

  .informacion {
    padding: 0px 40px;
  }

  .informacion h5{
    margin-top: 50px;
  }

  .datos {
    margin-top: 50px;
    box-shadow: 0px 10px 7px #e7e7e7;
  }

  .contador-image {
    width: 40px;
    height: 40px;
    margin-right: 5px;
  }

  .contador-image svg {
    width: 40px;
    height: 40px;
  }

  .contador {
    width: 50%;
    max-width: 50%;
  }

  .form-label {
    font-size: 0.8rem;
    left: 7px;
  }

  .contador-controles .btn-light, 
  .contador-controles .btn-light:hover, 
  .contador-controles .btn-light:focus, 
  .contador-controles .btn-light:active {
    padding: 0px 10px;
  }

  .precio {
    margin-top: 30px;
  }

  .seccionNomina_cajaBtn {
    margin-bottom: 30px;
  }

  .validacion_titulo {
    margin-top: 30px;
  }
}

@media (max-width: 767.98px) {

  .contador {
    width: 100%;
    max-width: 100%;
  }

  .contador-image {
    width: 50px;
    height: 50px;
    margin-right: 15px;
  }

  .contador-image svg {
    width: 50px;
    height: 50px;
  }

  .contador .form-contenedor {
    width: 76%;
  }

  .adjuntar {
    flex-direction: column;
    align-items: center;
  }

  .adjunto {
    width: auto;
    margin-top: 20px;
  }

  .rdt_Pagination{
    font-size: 0.6rem !important;
  }

  .rdt_Pagination span{
    margin: 0 5px !important;
  }
}

@media (max-width: 575px) {
  .contador-image {
    width: 40px;
    height: 40px;
    margin-right: 5px;
  }

  .contador-image svg {
    width: 40px;
    height: 40px;
  }

  .cargar_icono {
    width: 40px;
    height: 40px;
  }

  .adjunto_descripcion {
    min-width: 66%;
    margin-right: 5px;
  }

  .adjunto_icono {
    padding-right: 5px;
    padding-top: 0px;
  }

  .adjunto_icono svg {
    width: 15px;
    height: 20px;
  }

  .adjunto_accion--cargado svg {
    width: 20px;
  }

  .adjunto_eliminar svg {
    width: 20px;
  }

  .btnVerificar, 
  .btnVerificar:hover, 
  .btnVerificar:focus, 
  .btnVerificar:active, 
  .btnVerificar.btn:first-child:active {
    padding: 5px 20px;
    height: 35px;
  }
}
